var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('div',{staticClass:"flex justify-between mb-2 items-center"},[_c('h3',{staticClass:"text-2xl"},[_vm._v("Biblioteca virtual")]),(_vm.user.isAdmin)?_c('a-button',{staticClass:"w-full md:w-auto",attrs:{"to":{ name: 'create_document' }}},[_vm._v(" Crear documento ")]):_vm._e()],1),_c('a-table',{attrs:{"columns":_vm.columns,"source":_vm.pagination.data,"loading":_vm.loading,"toolbar-column-filter":"","pagination":_vm.pagination},on:{"paginate":function($event){return _vm.search({ page: $event })},"toolbar(refresh)":function($event){return _vm.search({ page: 1 })}},scopedSlots:_vm._u([{key:"toolbar(search)",fn:function(){return [_c('a-input',{attrs:{"is-search":"","placeholder":"Buscar..."},on:{"search":function($event){return _vm.search({ search: $event, page: 1 })}}})]},proxy:true},{key:"td(cover)",fn:function(ref){
var key = ref.key;
return [_c('div',{staticClass:"flex justify-center"},[_c('a-avatar',{attrs:{"image":"","picture":key,"size":"64px"}})],1)]}},{key:"td(download)",fn:function(ref){
var key = ref.key;
return [_c('a',{staticClass:"is-text-link text-indigo-500",attrs:{"href":key,"target":"_blank"}},[_vm._v(" "+_vm._s(key.split('/').slice(-1).join(''))+" ")])]}},{key:"td(type)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTypeString(item))+" ")]}},{key:"td(actions)",fn:function(ref){
var item = ref.item;
return [_c('a-dropdown',{scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('a-dropdown-item',{attrs:{"to":{ name: 'edit_document', params: { id: item.id } }}},[_vm._v(" Actualizar ")]),_c('a-dropdown-item',{on:{"click":function($event){return _vm.deleteDocument(item.id)}}},[_vm._v(" Eliminar ")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }