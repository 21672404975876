<template>
  <div class="p-1">
    <div class="flex justify-between mb-2 items-center">
      <h3 class="text-2xl">Biblioteca virtual</h3>
      <a-button :to="{ name: 'create_document' }" v-if="user.isAdmin" class="w-full md:w-auto">
        Crear documento
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })">
      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>
      <template #td(cover)="{ key }">
        <div class="flex justify-center">
          <a-avatar image :picture="key" size="64px" />
        </div>
      </template>
      <template #td(download)="{ key }">
        <a :href="key" class="is-text-link text-indigo-500" target="_blank">
          {{ key.split('/').slice(-1).join('') }}
        </a>
      </template>
      <template #td(type)="{ item }">
        {{ getTypeString(item) }}
      </template>
      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'edit_document', params: { id: item.id } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteDocument(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      with: 'specialization,course,semester,period',
      sort_by: 'title,asc'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.documents.pagination,
      loading: state => state.documents.loading
    }),
    columns() {
      return [
        { title: 'Imagen', key: 'cover_picture_path', slot: 'cover', alignment: 'center', style: () => ({ width: `140px` }), display: true },
        { title: 'Título', key: 'title', display: true },
        { title: 'Tipo', slot: 'type', key: 'type', display: true },
        // { title: 'Período', key: 'period', mutate: ({ key }) => key ? `${key.year}-${key.number}` : '', display: true },
        { title: 'Especialidad', key: 'specialization.title', display: true },
        { title: 'Semestre', key: 'semester', mutate: ({ key }) => key ? `${key.number}` : '', display: true },
        { title: 'Materia', key: 'course.title', display: true },
        { title: 'Descarga', slot: 'download', key: 'resource_path', display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', columnFilterable: false, display: this.user.isAdmin },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchDocuments: 'documents/index',
      deleteDocument: 'documents/delete'
    }),
    getTypeString(item) {
      switch (item.type) {
        case 1: return "Libro"
        case 2: return "Papel"
        case 3: return "Tesis"
        case 4: return "Otro"
        default: return "Tipo sin definir"
      }
    },
    search(query) {
      this.fetchDocuments({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ page: this.$route.query.page || 1 })
  }
}
</script>
